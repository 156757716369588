import moment from 'moment';

export default class PartyStatus {
  constructor(party) {
    this.party = party;
    return this.status; 
  }


  get status() {
    if (this.party.status === 'CANCELLED') return 'cancelled';
    if (!this.party.active) return 'inactive';
    if (moment().isBefore(this.party.date)) return 'pending';
    if (moment().isAfter(this.party.date) && moment().isBefore(this.party.endDate)) return 'inProgress';
    if (moment().isAfter(this.party.endDate || this.party.date)) return 'finished';

  }

  get isInProgress() {
    return this.status === 'inProgress';
  }
  get isCancelled() {
    return this.status === 'cancelled';
  }
  get isPending() {
    return this.status === 'pending';
  }
  get isActive() {
    return this.status !== 'inactive' && this.status !== 'finished' && this.status !== 'cancelled';
  }
}